﻿<div *ngIf="gridRows" id="rdo-grid">
	<div class="ui-table-missing-scrollable-header"></div>
	<p-table #rdoExpandedGrid [value]="gridRows" [dataKey]="dataKey" [styleClass]="'rdo-ui-table'" [rowHover]="false"
		[sortMode]="'single'" [sortField]="sorting.sortField" [sortOrder]="sorting.sortOrder" [scrollable]="true"
		[scrollHeight]="scrollHeight" [frozenValue]="frozenGridRows" [frozenWidth]="frozenWidth"
		[columns]="scrollableColumns" [frozenColumns]="frozenColumns" [customSort]="true" [lazy]="true"
		(onLazyLoad)="onLazyLoad($event)" (onRowExpand)="onRowExpand($event.data)"
		(onRowCollapse)="onRowCollapse($event.data)" (onSort)="changeSort($event)">

		<ng-template pTemplate="caption">
			<div>
				<rdo-column-selector *ngIf="showColumnSelector && !useProductTypeColumnSelector" [size]="'md'"
					[gridConfig]="gridConfig" (visibilityChanged)="saveVisibility()"
					(downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector>

				<rdo-column-selector-product-type *ngIf="showColumnSelector && useProductTypeColumnSelector"
					[size]="'md'" [gridConfig]="gridConfig" (visibilityChanged)="saveVisibility()"
					(downloadExcelClick)="downloadExcelClick.next(gridConfig)">
				</rdo-column-selector-product-type>
			</div>
		</ng-template>

		<ng-template pTemplate="colgroup" let-columns>
			<ng-container *ngFor="let group of columns">
				<ng-container *ngFor="let column of group.columns">
					<colgroup>
						<col [style.width]="getWidthStyle(column)" />
					</colgroup>
				</ng-container>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let group of columns" [style.width]="getColGroupWidth(group)"
					[attr.colspan]="group.columns.length" [class.expandable]="group.expandable"
					[class.group-end]="isGroupEnd(group)" id="{{group.title | rdoPathToId}}"
					[ngStyle]="group.headerStyle">
					<div data-toggle="tooltip" tooltip="{{getGroupTooltip(group)}}"
						[attr.title]="getGroupTooltip(group) ? null : translate(group.title)">{{translate(group.title)}}
					</div>
					<a *ngIf="!group.locked" (click)="toggleColumnVisibility(group)"
						title="{{'main.core.tables.common.hide_column_group' | translate}}">
						<span class="fa fa-lg fa-times-circle pull-right"></span>
					</a>
				</th>
			</tr>

			<tr>
				<ng-container *ngFor="let group of columns">
					<th *ngFor="let column of group.columns; let columnIndex = index" id="{{column.sortColumn}}"
						[pSortableColumn]="filterSortableColumns(column)" [ngStyle]="column.headerStyle"
						[style.width.px]="column.width || 110" [class.group-end]="isHeaderGroupEnd(group, column)"
						class="ellipsed-th">
						<div class="ellipsed-div">
							<!-- [ngStyle]="{'line-height': (!group.title) || !allowDoubleHeader ? '2.9em' : 'unset' }" -->
							<div tooltip="{{getColumnTooltip(column)}}"
								[attr.title]="getColumnTooltip(column) ? null : translate(column.title)"
								[ngStyle]="{'line-height': !allowDoubleHeader ? '2.9em' : 'unset' }">{{(column |
								rdoEllipsis:column.renderedWidth).processedTitle}}</div>
							<p-sortIcon [field]="column.sortColumn"></p-sortIcon>
						</div>
					</th>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenrows" let-rowData let-columns="columns">
			<tr class="frozen-row">
				<ng-container *ngFor="let group of columns">
					<td id="header-{{column.sortColumn}}-cell" *ngFor="let column of group.columns"
						[ngClass]="rowData[column.sortColumn].class" [ngStyle]="rowData[column.sortColumn].style"
						[style.width]="rowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(rowData[column.sortColumn].value)}}">
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl">{{rowData[column.sortColumn].value |
							translate}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value |
							translate}}</a>
						<span id="header-{{column.sortColumn}}-cell-text"
							*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)">
							{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex" let-expanded="expanded"
			let-columns="columns">
			<tr class="clickable-row" (mouseenter)="onMouseEnter(rowData)" (mouseleave)="onMouseLeave(rowData)"
				[pRowToggler]="rowData" [class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<ng-container *ngFor="let column of group.columns; let columnIndex = index">
						<td *ngIf="columnIndex === 0" id="detail-{{column.sortColumn}}-{{rowIndex}}"
							[ngClass]="rowData[column.sortColumn].class" [ngStyle]="rowData[column.sortColumn].style"
							[style.width]="rowData[column.sortColumn].width"
							[class.group-end]="isCellGroupEnd(group, column)"
							title="{{handleValue(rowData[column.sortColumn].value)}}">
							<i *ngIf="!rowData.childLoading"
								[ngClass]="expanded ? 'fa fa-fw fa-angle-down' : 'fa fa-fw fa-angle-right'"></i>
							<i *ngIf="rowData.childLoading" class="fa fa-fw fa-circle-o-notch fa-spin"></i>
							<span>{{rowData[column.sortColumn].value}}</span>
						</td>

						<td *ngIf="columnIndex !== 0" id="detail-{{column.sortColumn}}-{{rowIndex}}"
							[ngClass]="rowData[column.sortColumn].class" [ngStyle]="rowData[column.sortColumn].style"
							[style.width]="rowData[column.sortColumn].width"
							[class.group-end]="isCellGroupEnd(group, column)"
							title="{{handleValue(rowData[column.sortColumn].value)}}">
							<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
								class="fa fa-check"></i>
							<a *ngIf="rowData[column.sortColumn].linkDsl"
								(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
								[routerLink]="rowData[column.sortColumn].linkDsl">{{rowData[column.sortColumn].value}}</a>
							<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
								(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
								href="{{rowData[column.sortColumn].href}}">{{rowData[column.sortColumn].value}}</a>
							<span
								*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)">{{rowData[column.sortColumn].value}}</span>
						</td>
					</ng-container>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns" let-rowIndex="rowIndex">
			<tr class="clickable-row" (mouseenter)="onMouseEnter(rowData)" (mouseleave)="onMouseLeave(rowData)"
				[pRowToggler]="rowData" [class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<td id="detail-{{column.sortColumn}}-{{index}}" *ngFor="let column of group.columns"
						[ngClass]="rowData[column.sortColumn].class" [ngStyle]="rowData[column.sortColumn].style"
						[style.width]="rowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(rowData[column.sortColumn].value)}}">
						<i *ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && isBool(rowData[column.sortColumn].value) && rowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							[routerLink]="rowData[column.sortColumn].linkDsl">{{translate(rowData[column.sortColumn].value)}}</a>
						<a *ngIf="rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(rowData[column.sortColumn].config, rowData.raw)"
							href="{{rowData[column.sortColumn].href}}">{{translate(rowData[column.sortColumn].value)}}</a>
						<span
							*ngIf="!rowData[column.sortColumn].href && !rowData[column.sortColumn].linkDsl && !isBool(rowData[column.sortColumn].value)">{{translate(rowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
			<tr *ngFor="let childRowData of rowData.childRows" class="expansion-row"
				(mouseenter)="onMouseEnter(rowData)" (mouseleave)="onMouseLeave(rowData)"
				[class.row-hover]="rowData.hover">
				<ng-container *ngFor="let group of columns">
					<td id="detail-{{column.sortColumn}}-{{index}}" *ngFor="let column of group.columns"
						[ngClass]="childRowData[column.sortColumn].class"
						[ngStyle]="childRowData[column.sortColumn].style"
						[style.width]="childRowData[column.sortColumn].width"
						[class.group-end]="isCellGroupEnd(group, column)"
						title="{{handleValue(rowData[column.sortColumn].value)}}">
						<i *ngIf="!childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl && isBool(childRowData[column.sortColumn].value) && childRowData[column.sortColumn].value"
							class="fa fa-check"></i>
						<a *ngIf="childRowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(childRowData[column.sortColumn].config, childRowData.raw, rowData.raw)"
							[routerLink]="childRowData[column.sortColumn].linkDsl">{{translate(childRowData[column.sortColumn].value)}}</a>
						<a *ngIf="childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl"
							(click)="clickCellLink(childRowData[column.sortColumn].config, childRowData.raw, rowData.raw)"
							href="{{childRowData[column.sortColumn].href}}">{{translate(childRowData[column.sortColumn].value)}}</a>
						<span
							*ngIf="!childRowData[column.sortColumn].href && !childRowData[column.sortColumn].linkDsl && !isBool(childRowData[column.sortColumn].value)">{{translate(childRowData[column.sortColumn].value)}}</span>
					</td>
				</ng-container>
			</tr>
		</ng-template>

	</p-table>

	<p-paginator #gridPaginator id="gridPaginator" styleClass="rdo-paginator" [(first)]="paging.first"
		[rowsPerPageOptions]="[10,20,50]" [pageLinkSize]="10" [rows]="paging.pageSize" [totalRecords]="totalCount"
		(onPageChange)="onPage($event)">
	</p-paginator>

</div>
