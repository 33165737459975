import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { ChangesLogService } from './changes-log.service';
import { DownloadsService } from '../downloads';
import { HeaderService } from '../header';
import { PageOptions, SortOptions } from '../models';
import {
  BreadcrumbService,
  ColumnDefinitionService,
  MetricsGridConfig,
  IColumnConfig,
  ActiveFilterService,
  FormatService,
  GridSortingService,
} from '../core';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'changes-log.component.html',
  styleUrls: ['changes-log.component.scss'],
})
export class ChangesLogComponent implements OnInit, OnDestroy {
  private readonly DEFAULT_SORT_FIELD_NAME: string = 'DateCreated';

    public selectAllModel: Array<boolean> = [];
    public data: any;
    public other = new Array(20);
    public resultsLength: number = 0;
    public selectAllTitle = 'main.tabs.changes_log.select_all';
    public selectAll = false;
    public loading = true;
    public gridConfig: MetricsGridConfig;
    public paging: PageOptions = new PageOptions();
    public sorting: SortOptions;
    public subscriptions: any[] = [];
    public gridName: string = 'CHANGESLOG';

  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private breadcrumbService: BreadcrumbService,
    private changesLogService: ChangesLogService,
    private columnService: ColumnDefinitionService,
    //private headerService: HeaderService,
    private downloadService: DownloadsService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {}

  public ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptions(
      this.DEFAULT_SORT_FIELD_NAME,
      true
    );

    this.loading = false;
    this.breadcrumbService.update([{ title: 'main.tabs.changes_log.title' }]);
    this.configureGrid();
    this.load();
    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging = new PageOptions();
        this.load();
      })
    );
    this.subscriptions.push(
      this.changesLogService.changesLogCreated.subscribe(() => {
        this.load();
      })
    );
    if (
      this.authenticationService &&
      this.authenticationService.selectedClientId
    ) {
      this.subscriptions.push(
        this.authenticationService.selectedClientId.subscribe((clientid) => {
          this.load();
        })
      );
    }
    //this.subscriptions.push(this.headerService.userInfo.subscribe(userInfo => {
    this.subscriptions.push(
      this.authenticationService.userInfoView.subscribe((userInfo) => {
        if (this.gridConfig) {
          this.gridConfig.enableExcelExport =
            userInfo.HasClientAccessToExportData;
        }
      })
    );
  }

  public ngOnDestroy() {
    _.each(this.subscriptions, (s) => s.unsubscribe());
  }

  private load = () => {
    this.loading = true;
    this.selectAll = false;
    this.gridConfig.disableDeleteButton = true;
    this.updateTooltip();
    this.sorting.sortOn = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.descending =
      this.gridSortingService.getSortDirectionOrDefault() === -1;
    this.changesLogService
      .getChangeLogEntries(this.paging, this.sorting)
      .subscribe((result) => {
        this.data = result;
        this.resultsLength = result.Items.length;
        this.selectAllModel = new Array(this.resultsLength);
        this.other = new Array(this.resultsLength);
        this.loading = false;
      });
  };

  private changePageSize = (size: number) => {
    this.paging.pageSize = size;
    this.paging.page = 1;
    this.load();
  };

  private changePage = (pageNumber: number) => {
    if (pageNumber < 1) {
      pageNumber = 1;
    }
    this.paging.page = pageNumber;
    this.load();
  };

  private changeSortColumn = (column: IColumnConfig) => {
    const currentSortingColumn = this.gridSortingService.getSortFieldOrDefault(
      this.DEFAULT_SORT_FIELD_NAME
    );
    const alreadySortingOnThisColumn =
      column.sortColumn === currentSortingColumn;
    this.sorting.sortOn = currentSortingColumn;
    this.gridSortingService.setSortOption(column.sortColumn);
    if (alreadySortingOnThisColumn) {
      this.changeSortDirection();
    }
    this.load();
  };

  private changeSortDirection = () => {
    this.sorting.descending =
      this.gridSortingService.getSortDirectionOrDefault() === -1;
    this.gridSortingService.setSortOption(
      this.gridSortingService.getSortFieldOrDefault(
        this.DEFAULT_SORT_FIELD_NAME
      ),
      !this.sorting.descending
    );
    this.load();
  };

  private configureGrid = () => {
    this.gridConfig = new MetricsGridConfig(
      this.columnService.FullChangesLogColumnGroups(),
      null,
      true
    );
    this.gridConfig.enableDelete = true;
    this.gridConfig.disableDeleteButton = true;
  };

  private exportExcel = (args: any) => {
    this.loading = true;
    this.sorting.sortOn = this.gridSortingService.getSortFieldOrDefault(
      this.DEFAULT_SORT_FIELD_NAME
    );
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.changesLogService
        .getChangeLogsDownload(this.sorting, translatedConfig)
        .subscribe(
          (blob) => {
            this.loading = false;
            this.downloadService.saveExcelBlob(blob);
          },
          (error) =>
            this.toasterService.error(
              this.translateService.instant('main.tabs.changes_log.error'),
              error
            ),
          () => (this.loading = false)
        )
    );
  };

  private deleteChangeLogEntries = (args: any) => {
    this.loading = true;
    const deleteIds = [];
    for (let i = 0; i < this.selectAllModel.length; i++) {
      if (this.selectAllModel[i]) {
        deleteIds.push(this.data.Items[i].ChangeLogID);
      }
    }
    const recordsToDelete = deleteIds.length;
    const weArePastTheLastRecord =
      this.data.TotalCount - recordsToDelete <=
      this.paging.pageSize * this.paging.page - this.paging.pageSize;
    if (weArePastTheLastRecord && this.paging.page > 1) {
      this.paging.page = this.paging.page - 1;
    }
    this.subscriptions.push(
      this.changesLogService.deleteChangeLogs(deleteIds).subscribe((res) => {
        if (res) {
          const success = res && res.status === 200;
          const entryMsg =
            recordsToDelete > 1
              ? this.translateService.instant(
                  'main.tabs.changes_log.deleted_plural'
                )
              : this.translateService.instant(
                  'main.tabs.changes_log.deleted_singular'
                );
          const message = success
            ? recordsToDelete + entryMsg
            : this.translateService.instant(
                'main.tabs.changes_log.server_error'
              ) +
              `. ${recordsToDelete} ` +
              this.translateService.instant(
                'main.tabs.changes_log.records_involved'
              );
          if (success) {
            this.toasterService.success(
              this.translateService.instant('main.tabs.changes_log.success'),
              message
            );
          } else {
            this.toasterService.error(
              this.translateService.instant('main.tabs.changes_log.error'),
              message
            );
          }
        }
        this.load();
      })
    );
  };

  private toggleSelectAll = () => {
    for (let i = 0; i < this.selectAllModel.length; i++) {
      if (this.data.Items[i].IsMine) {
        this.selectAllModel[i] = this.selectAll;
      }
    }
    this.updateTooltip();
    this.gridConfig.disableDeleteButton = !this.selectAll;
  };

  private updateDeleteUi = (checked: boolean) => {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.selectAllModel.length; i++) {
      if (this.selectAllModel[i]) {
        this.gridConfig.disableDeleteButton = false;
        return;
      }
    }
    this.gridConfig.disableDeleteButton = true;
  };

  private updateTooltip = () => {
    this.selectAllTitle = this.selectAll
      ? 'main.tabs.changes_log.unselect_all'
      : 'main.tabs.changes_log.select_all';
  };
}
