import * as _ from 'lodash';
import {
  BreadcrumbService,
  InstructionInfo,
  ActiveFilterService,
  SortOptions,
} from './../../core';
import { SelectedProductTypeService } from './selected-product-type.service';
import { ProductTypeCard } from './../../models';
import { OnDestroy } from '@angular/core';

export class ProductTypesRouteParams {
  category: string;
  productType: string;
  geography: string;
  alert: string;
  sortOn: string;
  desc: boolean;
  reloadCards: boolean;
  useRentedAsProductType: boolean;
  rentedAsCatClass: string;

  constructor(params?: any) {
    this.productType = params.productType || null;
    this.category = params.category
      ? decodeURIComponent(params.category)
      : null;
    this.geography = params.geography
      ? decodeURIComponent(params.geography)
      : null;
    this.alert = params.alert || null;
    this.useRentedAsProductType =
      params.useRentedAsProductType === 'true' || false;
    this.sortOn = params.sortOn || '';
    this.desc = params.desc === 'true';
    this.reloadCards = params.reloadCards === 'true';
    this.rentedAsCatClass = params.rentedAsCatClass || '';
  }

  get sortOptions(): SortOptions {
    return { sortOn: this.sortOn, descending: this.desc };
  }

  // TODO: Why does the clone only return a few properties?
  clone = (): any => {
    return {
      category: this.category || '',
      sortOn: this.sortOn,
      desc: this.desc,
      useRentedAsProductType: this.useRentedAsProductType,
      rentedAsCatClass: this.rentedAsCatClass || '',
    };
  };
}

export class ProductTypeBaseComponent implements OnDestroy {
  // parameters
  protected params: ProductTypesRouteParams;

  protected subscriptions = [];

  constructor(
    protected selectedProductTypeService: SelectedProductTypeService,
    protected breadcrumbService: BreadcrumbService,
    public filterService: ActiveFilterService
  ) {}

  protected updateBreadcrumbs = (instructions: Array<InstructionInfo>) => {
    const category = this.params.category;
    const useRentedAsProductType: boolean = this.params.useRentedAsProductType;
    const baseInstructions: {
      title: string;
      linkDSL: (
        | string
        | { category?: string; useRentedAsProductType?: string }
      )[];
    }[] = [
      {
        title: category || 'main.tabs.equipment.all',
        linkDSL: ['/equipment', { category: category || '' }],
      },
    ];

    if (useRentedAsProductType) {
      baseInstructions.push({
        title: this.selectedProductTypeService.selectedProductType.Description,
        linkDSL: [
          'product-types',
          this.selectedProductTypeService.selectedProductType.ProductType,
          {
            category: category || '',
            useRentedAsProductType: useRentedAsProductType ? 'true' : 'false',
          },
          'rate-types',
        ],
      });
    } else {
      baseInstructions.push({
        title: this.selectedProductTypeService.selectedProductType.Description,
        linkDSL: [
          'product-types',
          this.selectedProductTypeService.selectedProductType.ProductType,
          { category: category || '' },
        ],
      });
    }

    const breadcrumbInstructions = _.union<InstructionInfo>(
      baseInstructions,
      instructions
    );
    this.breadcrumbService.update(breadcrumbInstructions);
  };

  protected getCurrentFilter = () => {
    const filter = this.filterService.getCurrentFilter();
    // eslint-disable-next-line
    if (filter && filter.hasOwnProperty('dateFrom')) {
      return filter;
    }

    const options = sessionStorage.getItem('FILTER');
    const result = !options ? JSON.parse('{}') : JSON.parse(options);
    result.dateFrom = new Date(result.dateFrom);
    result.dateTo = new Date(result.dateTo);

    return result;
  };

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  protected hasRouteChanged = (
    existingParams: ProductTypesRouteParams,
    newParams: ProductTypesRouteParams
  ): boolean => {
    if (!existingParams) {
      return true;
    }

    return (
      existingParams.productType !== newParams.productType ||
      existingParams.category !== newParams.category ||
      existingParams.geography !== newParams.geography ||
      existingParams.alert !== newParams.alert
    );
  };

  protected reloadCards = (
    existingParams: ProductTypesRouteParams,
    newParams: ProductTypesRouteParams
  ): boolean => {
    if (!existingParams) {
      return true;
    }

    return (
      existingParams.category !== newParams.category ||
      existingParams.geography !== newParams.geography ||
      existingParams.alert !== newParams.alert ||
      newParams.reloadCards
    );
  };

  protected hasProductTypeChanged = (
    existingParams: ProductTypesRouteParams,
    newParams: ProductTypesRouteParams
  ): boolean => {
    if (!existingParams) {
      return true;
    }

    return existingParams.productType !== newParams.productType;
  };

  protected getRouteParams = (params): ProductTypesRouteParams => {
    const routeParams = new ProductTypesRouteParams(params);
    return routeParams;
  };
}
