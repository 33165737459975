import { Observable } from 'rxjs/Observable';
import cloneDeep from 'lodash/cloneDeep';

import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { IGridExportColumn } from './IGridExportColumn.interface';
import { IColumnConfig } from './IColumnConfig.interface';

export type GridRowConfig = {
  raw: Record<string, any>[];
  children: unknown[];
  childRows: unknown;
  childLoading: boolean;
  expanded: boolean;
  isExpandable: boolean;
};

export type ColumnSelectorItemConfig = {
  type: 'column' | 'group';
  title: string;
  target: IColumnConfig | IGridColumnGroup;
  parentId?: number;
};

export type ColumnSelectorConfig = {
  headerList: string[];
  itemList: Record<number, ColumnSelectorItemConfig[]>;
};

// Instead of having a long list of parameters that will have to be assigned a null value to get
// to the property you need, started moving to a configuration object instead.
export type MetricsGridConfigOptions = {
  isMovable?: boolean;
  hooks?: {
    afterRowConfigHook?: (config: GridRowConfig) => GridRowConfig;
    afterRestoreLayout?: () => void;
    beforeColumnVisibilityChange?: (
      group: any,
      config: MetricsGridConfig
    ) => boolean;
    afterColumnVisibilityChange?: (
      group: any,
      config: MetricsGridConfig
    ) => void;
    beforeMoveColumn?: (
      group: any,
      config: MetricsGridConfig,
      direction: 'left' | 'right'
    ) => boolean;
    afterMoveColumn?: () => void;
  };

  // Add columns to the column-selector-product-type.component without changing the original component's hard-coded configuration.
  // See ColumnSelectorProductTypeComponent.buildChildren and CategoriesWithRentedAsComponent.configureGrid for example of usage.
  columnSelector?: ColumnSelectorConfig;
};

export interface IMetricsGridConfig {
  lastUpdated?: Date;
  groups: Array<IGridColumnGroup>;
  rowExpansion?: (paren: any) => Observable<Array<any>>;
  enableExcelExport?: boolean;
  enableDelete?: boolean;
  disableDeleteButton?: boolean;
  options?: MetricsGridConfigOptions;
  getAllColumns: () => Array<IGridExportColumn>;
  cloneAndTranslate(translate: (text: string) => string): MetricsGridConfig;
}

export class MetricsGridConfig implements IMetricsGridConfig {
  public lastUpdated?: Date;

  constructor(
    public groups: Array<IGridColumnGroup>,
    public rowExpansion?: (paren: any) => Observable<Array<any>>,
    public enableExcelExport?: boolean,
    public enableDelete?: boolean,
    public disableDeleteButton?: boolean,
    public options?: MetricsGridConfigOptions
  ) {
    this.setGroupPairings();
  }

  /**
   * Get a list of all columns in grid.
   * @param hideHiddenColumns - Hide columns that have been explicitly set visible false.
   */
  public getAllColumns(
    hideHiddenColumns: boolean = false
  ): Array<IGridExportColumn> {
    const columns: Array<IGridExportColumn> = [];
    this.groups.forEach((g) => {
      if (g.visible) {
        g.columns.forEach((c) => {
          if (!c.skipExport) {
            if (hideHiddenColumns && c.visible === false) {
              return;
            }
            columns.push({
              group: g.title,
              isPercent: c.isPercent,
              isGroupingEnabled: c.isGroupingEnabled,
              displayName: g.title + ' ' + c.title,
              field: c.field,
              minuend: c.minuend,
              subtrahend: c.subtrahend,
              isString: c.isString,
              showPercentDifferenceFrom: c.showPercentDifferenceFrom,
              hasDecimals: c.hasDecimals,
            });
          }
        });
      }
    });
    return columns;
  }

  /**
   * Deep clones this object and translates group titles, group column selector titles and
   * column titles.
   */
  public cloneAndTranslate(
    translate: (text: string) => string
  ): MetricsGridConfig {
    const newConfig = cloneDeep(this);
    newConfig.groups.forEach((group) => {
      group.title = translate(group.title);
      group.columnSelectorTitle = translate(group.columnSelectorTitle);
      if (group.columns && group.columns.length) {
        group.columns.forEach((column) => {
          column.title = translate(column.title);
        });
      }
    });
    return <MetricsGridConfig>newConfig;
  }

  /**
   * Calculates an arroy of paired column groups based on the columnSelectorTitle. Bind
   * groups together by putting paired column columnSelectors of the other columns into
   * the pairedColumns array.
   * @private
   */
  private setGroupPairings(): void {
    this.groups.forEach((group) => {
      group.columns.forEach((col) => {
        if (col.pairedColumns) {
          col.pairing = [...col.pairedColumns, col.title];
        }
      });
    });
  }

  public clone(): MetricsGridConfig {
    return cloneDeep(this);
  }
}
